import React from 'react';
import { graphql } from 'gatsby';

import { usesArticle } from 'lib/article-hoc';

import Layout from 'components/Layout';
import Article from 'components/Article';

const PostTemplate = ({
  article,
  data: {
    markdownRemark: {
      frontmatter
    }
  },
  pageContext
}) => <Layout
  container="article"
  canonical={ pageContext.slug }
  { ...frontmatter }>
  <Article { ...article } />
</Layout>;

export default usesArticle(PostTemplate, { bodyIn: 'html' });

export const postQuery = graphql`
  query postBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      ...ArticleDetails
    }
  }
`;
